import qs from "querystring"
import { useRouter } from "next/router"
import { createContext, useContext, useEffect } from "react"
import Head from "next/head"
import { useMachine } from "@xstate/react"
import * as Sentry from "@sentry/nextjs"
import { getServerSession } from "next-auth"
import Details from "../../components/campaign/Details"
import Expired from "../../components/campaign/Expired"
import { Campaign } from "../../types"
import {
  acronymize,
  getLiveDateRange,
  hasBeenPublished,
  stripTags,
  truncate
} from "../../utils/helpers"
import {
  donationAmountMachine,
  languageMachine,
  productCodesMachine,
  recurringMachine
} from "../../machines"
import { authOptions } from "../api/auth/[...nextauth]"
import axios from "axios"
import { UserData } from "../api/user"
import { XStateContext } from "../../contexts/XStateCtx"
import { NEW_PAYMENT_METHOD } from "../../components/campaign/Payment"

const HeadMetadata = ({ campaign, description }) => (
  <Head>
    <title>{campaign?.title || ""}</title>
    <meta charSet="UTF-8" />
    <meta name="viewport" content="initial-scale=1.0,width=device-width" />
    <meta name="description" content={description} />
    <meta name="revisit-after" content="1 days" />

    <meta property="fb:app_id" content={process.env.NEXT_PUBLIC_FACEBOOK_ID} />
    <meta property="og:title" content={campaign?.title || ""} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={``} />
    <meta property="og:site_name" content={campaign?.brand || ""} />
    <meta property="og:description" content={description || ""} />

    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:site"
      content={process.env.NEXT_PUBLIC_TWITTER_HANDLE}
    />
    <meta name="twitter:title" content={campaign?.title || ""} />
    <meta name="twitter:description" content={description || ""} />

    {campaign?.social_image ? (
      <>
        <meta property="og:image" content={campaign?.social_image} />
        <meta name="twitter:image" content={campaign?.social_image} />
        <link rel="image_src" type="image/jpeg" href={campaign?.social_image} />
      </>
    ) : null}

    <link rel="shortcut icon" type="text/html" href="/favicon.ico" />
  </Head>
)

const renderBody = ({
  campaign,
  preview,
  range,
  userData,
  abbreviatedBrand,
  isApplePayEnabled,
  isGooglePayEnabled
}) => {
  // If this is a preview or if the campaign is live based on it's date range, show it
  if (preview || range) {
    return (
      <div
        className={abbreviatedBrand}
        data-brand={abbreviatedBrand}
        data-ends={range?.ends}
      >
        <Details userData={userData} campaign={campaign} isApplePayEnabled={isApplePayEnabled} isGooglePayEnabled={isGooglePayEnabled} />
      </div>
    )
  }

  // if the campaign has been published but is not live, show the expired view
  else if (hasBeenPublished(campaign?.dates)) {
    return (
      <div className={abbreviatedBrand} data-brand={abbreviatedBrand}>
        <Expired campaign={campaign} />
      </div>
    )
  }

  // if the campaign does not meet these criterea show a not found message
  else {
    return <div>Campaign not found</div>
  }
}

export async function getServerSideProps(context) {
  function getUserInfo(session, baseUrl): Promise<UserData | null> {
    return new Promise(async (resolve, reject) => {
      if (session) {
        try {
          const { data } = await axios.get<UserData>(
            `${baseUrl}/dynamic-form-info/`,
            {
              headers: {
                Authorization: `Bearer ${session.id_token}`
              }
            }
          )
          resolve(data)
        } catch (e) {
          reject(null)
        }
      } else {
        resolve(null)
      }
    })
  }

  try {
    const session = await getServerSession(
      context.req,
      context.res,
      authOptions
    )
    const baseUserEndpointURL = [process.env.LIGHOST, "api/v1/user"].join("/")

    const domainBrand = process.env.NEXT_PUBLIC_BRAND
    const id = context.params.id
    const slug = context.params.slug
    const preview = context.query.preview
    const abbBrand = context.query.abbBrand
    const redirect = context.query.redirect

    if (!process.env.LIGAUTH) {
      throw new Error("Please provide a value for LIGAUTH")
    }

    const response = await fetch(
      `${process.env.LIGHOST}/api/v1/campaigns/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.LIGAUTH}`
        }
      }
    )

    const campaign: Campaign = await response.json()

    let userData = null

    if (!campaign?.offer?.new_users_only) {
      try {
        userData = await getUserInfo(session, baseUserEndpointURL)
      } catch (e) {
        userData = null
      }
    }

    const campaignDoesNotExist = !campaign?.slug || !campaign?.id
    const campaignSlugDoesNotMatch =
      campaign && campaign.slug !== slug && campaign.id === id

    const campaignAndDomainBrandsDoNotMatch =
      campaign?.brand != "Ministry Partners" && domainBrand != campaign.brand

    if (campaignDoesNotExist) {
      const querystring = preview ? `?${qs.stringify({ preview })}` : ""

      return {
        redirect: {
          destination: "/" + querystring
        }
      }
    } else if (campaignSlugDoesNotMatch) {
      const querystring = preview ? `?${qs.stringify({ preview })}` : ""

      return {
        redirect: {
          destination: `/${campaign.id}/${campaign.slug}` + querystring
        }
      }
    } else if (campaignAndDomainBrandsDoNotMatch) {
      if (redirect !== "true" && !abbBrand) {
        const abbreviatedCampaignBrand = acronymize(campaign.brand)

        if (process.env.NODE_ENV !== "production") {
          // if we're in dev mode, the redirect and abbBrand params
          // signify a redirect due to mimatched brands. This allows
          // us to spoof the correct behavior of having the page
          // redirect once to the correct brand even though on local
          // machines we're only running on localhost:3000
          const querystring = qs.stringify({
            redirect: "true",
            abbBrand: abbreviatedCampaignBrand,
            ...(preview && { preview })
          })

          return {
            redirect: {
              destination: `/${campaign.id}/${campaign.slug}?${querystring}`
            }
          }
        } else {
          const querystring = preview ? `?${qs.stringify({ preview })}` : ""

          return {
            redirect: {
              destination: `/${campaign.id}/${campaign.slug}` + querystring
            }
          }
        }
      }
    }

    return {
      props: {
        campaign,
        userData
      }
    }
  } catch (error) {
    console.error("[slug] getServerSideProps threw an error", error)
    Sentry.captureException(error)

    return {
      props: {}
    }
  }
}

type CampaignPageProps = {
  campaign: Campaign
  userData: UserData
}

export const ServiceContext = createContext(null)

export default function CampaignPage({
  campaign,
  userData,
}: CampaignPageProps) {
  const router = useRouter()
  // This non-conventional method of implementing the machine
  // is being refactored in the remix rewrite of this site
  const [, sendLanguage, languageService] = useMachine(languageMachine)
  const [, , donationAmountService] = useMachine(donationAmountMachine)
  const [, , recurringService] = useMachine(recurringMachine)
  const [, , productCodesService] = useMachine(productCodesMachine)

  // Use the more conventional method of implementating a service
  const xStateServices = useContext(XStateContext)
  const { send: sendPaymentMethod } = xStateServices.paymentMethodsService

  useEffect(() => {
    sendLanguage({
      type: "CHANGE",
      value: campaign?.language
    })
  }, [campaign?.language, sendLanguage])

  useEffect(() => {
    const isCanadaOrRBC =
      campaign?.brand === "Ligonier Ministries Canada" ||
      campaign?.brand === "Reformation Bible College"

    if (
      userData?.payment_methods?.length &&
      !campaign?.offer?.free &&
      !campaign?.offer?.new_users_only &&
      !isCanadaOrRBC
    ) {
      sendPaymentMethod({
        type: "SET_USER_PAYMENT_METHODS",
        data: userData.payment_methods
      })
      // Select first payment method on load
      // or the deafult NEW_PAYMENT_METHOD
      sendPaymentMethod({
        type: "SET_SELECTED_PAYMENT_METHOD",
        data: `${userData.payment_methods?.[0]?.id}`
      })
    } else {
      sendPaymentMethod({
        type: "SET_SELECTED_PAYMENT_METHOD",
        data: NEW_PAYMENT_METHOD
      })
    }
  }, [userData, campaign])

  const {
    preview,
    // abbreviated brand in the query params
    abbBrand
  } = router.query
  const domainBrand = process.env.NEXT_PUBLIC_BRAND
  const abbreviatedBrand = (abbBrand as string) || acronymize(domainBrand)

  if (!campaign) {
    return null
  }

  if (!campaign?.id || !campaign?.slug) return null

  const range = getLiveDateRange(campaign?.dates)
  
  const description = truncate(stripTags(campaign?.description), 170, "…", " ")

  const isApplePayEnabled = process.env.NEXT_PUBLIC_FEATURE_FLAG_APPLE_PAY === "true"
  const isGooglePayEnabled =  process.env.NEXT_PUBLIC_FEATURE_FLAG_GOOGLE_PAY === "true"

  return (
    <>
      <ServiceContext.Provider
        value={{
          languageService,
          donationAmountService,
          recurringService,
          productCodesService
        }}
      >
        <HeadMetadata campaign={campaign} description={description} />
        {renderBody({
          campaign,
          userData,
          preview,
          range,
          abbreviatedBrand,
          isApplePayEnabled,
          isGooglePayEnabled
        })}
      </ServiceContext.Provider>
    </>
  )
}
